html,
body {
  background-color: #222;
}

html {
  min-height: 100%;
  position: relative;
}

body.riotbar-present {
  margin-bottom: calc(241px + 6rem) !important;
}

h1,
h2,
h3,
h4 {
  font-family: "MarkPro", Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

h4.panel-title {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
}
h1 {
  font-size: 70px;
  line-height: 60px;
}
h2 {
  font-size: 60px;
  line-height: 50px;
}
h3 {
  font-size: 50px;
  line-height: 40px;
}
h4 {
  font-size: 40px;
  line-height: 30px;
}
pre {
  overflow: auto;
  word-wrap: normal;
  white-space: pre;
}

a:link,
a:visited {
  /* bright red */
  color: #ed3029;
}

a:hover {
  color: #c0392b;
}

a:active {
  color: #c0392b;
}

.App-logo {
  height: 80px;
  padding-bottom: 15px;
}

.small-header-logo {
  height: 20px;
  padding-right: 10px;
}

/* NAVIGATION BAR */
.navbar-right {
  padding: 8px;
  padding-bottom: 0;
}

.navbar-text {
  margin: 0;
  font-family: "MarkPro", Arial, sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}

a.navbar-brand:link,
a.navbar-brand:visited {
  color: #ffffff;
}

a.navbar-brand:hover {
  color: #c0392b;
}

.navbar-brand {
  font-family: "MarkPro", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  padding: 5px 15px;
}

.navbar {
  background-color: #1b1b1b;
  background-image: -moz-linear-gradient(top, #222222, #111111);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
  background-image: -webkit-linear-gradient(top, #222222, #111111);
  background-image: -o-linear-gradient(top, #222222, #111111);
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  border-color: #252525;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  height: 80px;
}

.navbar-separator-bar {
  border-right: 2px solid #5c5c5ccc;
}

.navbar-button {
  display: inline-block;
  height: 18px;
  width: auto;
  color: white;
  background: none;
  border: none;
}
