.reversal-panel {
  color: #dbdbdb;
  font-family: "MarkPro", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  font-size: 20px;
}

.reversal-panel__icon--game {
  width: 30px;
}

.MuiSvgIcon-root.reversal-panel__icon--platform {
  width: 30px;
  height: 30px;
}

.reversal-panel-header {
  font-size: 50px;
  color: #dbdbdb;
  text-transform: capitalize;
}

.reversal-panel-table {
  display: table;
  font-size: 20px;
  border: 2px solid #f9f9f9;
  margin-top: 20px;
}

.reversal-panel-table__head {
  border: 2px solid #f9f9f9;
}

.reversal-panel-column__game,
.reversal-panel-column__platform {
  text-align: center;
}

.reversal-panel-table__row {
  border-left: 2px solid #f9f9f9;
  border-right: 2px solid #f9f9f9;
  border-top: 1px solid lightgray;
}

.reversal-panel-table th {
  padding: 20px 30px;
  font-weight: 700;
}

.reversal-panel-table td {
  padding: 20px 30px;
}

.reversal-panel-footer {
  font-size: 32px;
  text-align: right;
  color: #f9f9f9;
  font-family: "MarkPro", Arial, sans-serif;
}

.reversal-panel-footer__text {
  white-space: nowrap;
}

.reversal-panel-footer__button {
  width: 140px;
  height: 50px;
  background: #c0392b;
  text-transform: uppercase;
  border: none;
  margin: 20px 0;
  margin-left: 30px;
}

.reversal-panel-error {
  margin: 0;
  margin-top: 20px;
}

@media only screen and (max-width: 1225px) {
  .reversal-panel-column__platform {
    display: none;
  }

  .reversal-panel-column__original {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .reversal-panel-column__game {
    display: none;
  }

  .reversal-panel-header {
    text-align: center;
  }

  .reversal-panel-footer {
    text-align: center;
  }
}

@media only screen and (max-width: 750px) {
  .reversal-panel-column__chargeback {
    display: none;
  }

  .reversal-panel-table th {
    padding: 20px;
  }

  .reversal-panel-table td {
    padding: 20px;
  }
}
