@font-face {
  font-family: "MarkPro";
  src: url("../fonts/MarkPro-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "MarkPro";
  src: url("../fonts/MarkPro-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "MarkPro";
  src: url("../fonts/MarkPro-Bold.ttf") format("truetype");
  font-weight: 700;
}

.card {
  background-color: #131313;
  padding: 25px 30px;
  border-radius: 0px;
  box-shadow: 0px 6px 20px 6px #000000;
  -moz-box-shadow: 0px 6px 20px 6px #000000;
  -webkit-box-shadow: 0px 6px 20px 6px #000000;
  color: #dbdbdb;
  font-family: "MarkPro", Arial, sans-serif;
}

.reversal-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
}

.reversal-row__strike {
  font-style: italic;
}

.list-group {
  background-color: #131313 !important;
  font-size: 20px;
}

.list-group-item {
  background-color: #131313 !important;
  font-size: 20px;
}

table.list-group {
  display: table;
}

.list-group th {
  padding: 0 20px;
  white-space: nowrap;
}

.list-group td {
  padding: 0 20px;
  white-space: nowrap;
}

.list-number {
  text-align: right;
}

.navbar-brand {
  font-size: 25px;
}

.navbar-text {
  font-size: 20px;
}

.card-header {
  font-size: 50px;
}

.table-body {
  overflow-x: scroll;
}

.card-body {
  font-size: 20px;
}

.info-card {
  margin: auto;
}

.no-balance-container {
  margin: 20px 0;
}

.flex-basis {
  flex-basis: 800px;
}

.host-header {
  text-transform: uppercase;
}

.riotbar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 0 !important;
}

.riotbar-footer .riotbar-footer-content {
  padding: 1px 5px 10px;
}

.reversal-instances {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #dbdbdb;
  margin-bottom: 10px;
}

.reversal-instances__header {
  margin-bottom: 30px;
  font-size: 60px;
  font-weight: 500;
  text-align: center;
}

.reversal-instances-subheader {
  font-size: 20px;
  text-align: center;
}

.reversal-instances-subheader__text {
  margin: 0;
  max-width: 800px;
  margin-bottom: 20px;
}
